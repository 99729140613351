<template>
  <div class="transportPayAndInvoice">
    <h1>运费往来表<!-- 运费支付与发票信息对照表 --></h1>
    <el-form :inline="true">
      <el-form-item label="申请付款日期">
        <el-date-picker v-model="searchParam.payDates" type="daterange" start-placeholder="起" end-placeholder="止" style="width: 220px;" />
      </el-form-item>
      <el-form-item label="运输公司">
        <el-select v-model="searchParam.transportCompany" filterable style="width: 120px;" clearable>
          <el-option v-for="transportCompanyItem in show.transportCompanyList" :key="transportCompanyItem.id" :label="transportCompanyItem.value" :value="transportCompanyItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" @selection-change="selectionChange()" ref="tabRef" border show-summary :summary-method="summary" style="height: calc(100vh - 310px);">
      <el-table-column prop="payDate" label="申请付款日期" show-overflow-tooltip />
      <el-table-column prop="transportCompany" label="运输公司" show-overflow-tooltip />
      <el-table-column prop="invoiceQuantity" label="开票数量" show-overflow-tooltip />
      <el-table-column prop="invoiceAmount" label="开票金额" show-overflow-tooltip />
      <el-table-column prop="payAmount" label="付款金额" show-overflow-tooltip />
      <el-table-column prop="residueAmount" label="当期结余" width="150" show-overflow-tooltip />
      <el-table-column prop="residueAmountAccumulate" label="累计余额" width="150" show-overflow-tooltip />
    </el-table>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const tabRef = ref()

const data = reactive({
  total: 0,
  list: [],
  payAmount: null,
  invoiceAmount: null,
  invoiceQuantity: null,
  residueAmountAccumulate: null,
})

const show = reactive({
  selected: [],
  transportCompanyList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'transportCompany' } }).then(res => {
  show.transportCompanyList = res.dictList
})

var beforeMonth = new Date()
beforeMonth.setMonth(beforeMonth.getMonth() - 1)
const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.payDates = [beforeMonth, new Date()]
  searchParam.transportCompany = null
}
resetSearchParam()

const commitFindList = () => {
  if (!searchParam.payDates || searchParam.payDates.length == 0) {
    ElMessage.error('请选择日期')
    return
  }
  const params = {}
  params.payDateGe = util.parseTime(searchParam.payDates[0], '{y}-{m}-{d}')
  params.payDateLe = util.parseTime(searchParam.payDates[1], '{y}-{m}-{d}')
  if (searchParam.transportCompany) {
    params.transportCompanyList = [searchParam.transportCompany]
  } else {
    params.transportCompanyList = show.transportCompanyList.map(c => c.value)
  }
  api.post('/backend/transportPay/invoiceAndPay', params).then(res => {
    data.list = res.list
    data.total = res.total
    data.payAmount = res.payAmount
    data.invoiceAmount = res.invoiceAmount
    data.invoiceQuantity = res.invoiceQuantity
    data.residueAmountAccumulate = res.residueAmountAccumulate
  })
}

const summary = () => {
  return ['合计', '', data.invoiceQuantity, data.invoiceAmount, data.payAmount, '', data.residueAmountAccumulate]
}

const selectionChange = () => {
  show.selected = tabRef.value.getSelectionRows()
}
</script>

<style lang="less"></style>